._3hjKOo {
  text-align: center;
}
/* Guarantee section */
.vC-J32 {
  padding: 40px 0;
}
@media all and (max-width: 1280px) {
._1VtsFP {
    margin: 15px 0
}
  }
._1kcYYv {
  margin: 0;
}
._2E9jlm {
  display: block;
  font-size: 50px;
  line-height: 1em;
  margin-bottom: 15px;
}
/* Steps section */
._2eWLqt {
  padding: 50px 0;
}
._125_Ms {
  background: url(https://app-staging.mozio.com/20250403170632-3cab1d0/2b11c2241af12fbb39bab6b3b47c8c78.png) 0 0;
  background-size: 300px 100px;
  width: 100px;
  height: 100px;
}
._3DklEg {
  margin: 0 auto 10px;
  height: 100px;
  width: 100px;
  border-radius: 50%;
}
._34Osx3 {
  background-position: 0 0;
}
._1zkVUV {
  background-position: -100px 0;
}
.UhNxJ8 {
  background-position: -200px 0;
}
._3sC_Sa {
  margin: 0;
  font-weight: 300;
}
._17ZO17 {
  margin: 0;
  font-weight: 300;
  position: relative;
  font-size: 18px
}
._17ZO17:after {
    visibility: hidden;
  }
._17ZO17 + ._17ZO17:after {
      visibility: visible;
      content: '\E315';
      font-size: 30px;
      position: absolute;
      top: 40px;
      left: -5px
    }
@media all and (max-width: 960px) {
    ._17ZO17 + ._17ZO17:after {
        content: '\E313';
        font-size: 30px;
        top: -45px;
        left: 50%;
        margin-left: -15px
    }
      }
@media all and (max-width: 960px) {

  ._17ZO17 + ._17ZO17 {
      margin-top: 60px
  }
    }
._3VDuRV {
  padding: 0 50px;
}
.fpea_D {
  text-align: center;
  margin: 35px 0 0;
}
._1Bw63Q {
  display: inline-table
}
@media all and (max-width: 600px) {
._1Bw63Q {
    width: 90%
}
  }
@media all and (min-width: 960px) {
.rtl ._17ZO17 + ._17ZO17:after {
      right: -5px;
      left: auto;
      left: initial;
      content: 'chevron_left'
  }
    }
/* GBT specific styles */
.corp-gbt ._1kcYYv {
    color: #4d4f53;
  }
.corp-gbt ._2E9jlm {
    fill: #7e1e7a;
  }
.corp-gbt ._3DklEg {
    background: transparent;
  }
.corp-gbt ._1Bw63Q {
    border-radius: 3px;
    text-transform: none;
    padding: 10px 40px;
  }
.corp-gbt ._34Osx3 {
    background: url(https://app-staging.mozio.com/20250403170632-3cab1d0/c91dbbb88fb99b0f9d23878e77a84256.png) 0 0;
    background-size: 300px 100px;
    background-position: 0 0;
  }
.corp-gbt ._1zkVUV {
    background: url(https://app-staging.mozio.com/20250403170632-3cab1d0/c91dbbb88fb99b0f9d23878e77a84256.png) 0 0;
    background-size: 300px 100px;
    background-position: -100px 0;
  }
.corp-gbt .UhNxJ8 {
    background: url(https://app-staging.mozio.com/20250403170632-3cab1d0/c91dbbb88fb99b0f9d23878e77a84256.png) 0 0;
    background-size: 300px 100px;
    background-position: -200px 0;
  }
.corp-gbt ._17ZO17 {
    padding: 0 50px;
  }
.corp-gbt ._17ZO17 + ._17ZO17:after {
        content: url(data:image/svg+xml;base64,PHN2ZyBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHN0eWxlPi5zdDB7ZmlsbDojMDAyNjYzfTwvc3R5bGU+PHBhdGggY2xhc3M9InN0MCIgZD0iTTEyLjQgMy4xSDYuOGw1LjUgOC45LTUuNSA4LjloNS42bDUuMy04Ljl6Ii8+PC9zdmc+);
        color: #002663;
        font-weight: bold
      }
@media all and (max-width: 960px) {
      .corp-gbt ._17ZO17 + ._17ZO17:after {
          content: url(data:image/svg+xml;base64,PHN2ZyBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHN0eWxlPi5zdDB7ZmlsbDojMDAyNjYzfTwvc3R5bGU+PHBhdGggY2xhc3M9InN0MCIgZD0iTTEyLjQgMy4xSDYuOGw1LjUgOC45LTUuNSA4LjloNS42bDUuMy04Ljl6Ii8+PC9zdmc+);
          -webkit-transform: rotate(90deg);
                  transform: rotate(90deg)
      }
        }
