._2Ivkrf {
  padding: 70px;
  text-align: center;

  overflow: hidden;
  background: url(https://app-staging.mozio.com/20250403170632-3cab1d0/def373525ad8b2c1b9d009b2ab7f8354.png) no-repeat bottom;
  background-size: cover;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}
._1J1ZpW {
  padding: 70px 20px;
  text-transform: capitalize;
}
._2JrAH1 {
  font-size: 34px;
  line-height: 34px;
  font-weight: bold;
  color: #FFFFFF;
}
