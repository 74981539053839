._33UdII {
  position: relative;
  background: #eee;
}

  ._33UdII h1 {
    font-size: 30px;
    color: #000;
    font-weight: 300;
    padding: 30px 0;
    text-align: center;
  }
._2Sp_2u {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  height: 400px;
}
._-2FDqZ {
  margin: 15px 0;
  text-align: center;
}
._-2FDqZ h3 {
    color: #000;
    font-size: 24px;
    margin: 0;
  }
._-2FDqZ h4 {
    font-size: 18px;
    margin: 0 0 45px;
  }
.sRRu5c {
  position: relative;
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
  height: 120px;
}
.sRRu5c > ul {
    width: 100%;
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0;
  }
@media all and (max-width: 960px) {
.sRRu5c {
    height: 60px
}
  }
._3OPA4m {
  position: relative;
  width: 120px;
  margin: 0 auto;
  display: none;
}
._85DYBF {
  width: 38px;
  height: 38px;
  background: #0D84EB;
  margin: 0 auto;
  border-radius: 50%;
}
._1NWytH ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
._1NWytH ul li {
      cursor: pointer;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 40px;
    }
._1NWytH ul li i {
        margin: 8px 0 0;
      }
._1NWytH ul li:first-child {
        left: 0;
      }
._1NWytH ul li:last-child {
        right: 0;
      }
._1uVZec {
  background: url(https://app-staging.mozio.com/20250403170632-3cab1d0/79025e6c49a46dbbd903ba2a24715cf4.png) no-repeat;
  background-size: 26px 195.5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
._1xmOck {
  width: 27px;
  height: 20px;
}
._3YHICl {
  width: 26px;
  height: 13px;
  background-position: 0 -30px;
}
.uGHxw9 {
  width: 26px;
  height: 14px;
  background-position: 0 -56px;
}
._2DHUoQ {
  width: 26px;
  height: 13px;
  background-position: 0 -82px;
}
._2WBcR7 {
  width: 26px;
  height: 13px;
  background-position: 0 -109px;
}
.iToOFk {
  width: 26px;
  height: 13px;
  background-position: 0 -135px;
}
._2jS_C_ {
  width: 26px;
  height: 13px;
  background-position: 0 -161px;
}
._3ZBqd4 {
  width: 26px;
  height: 13px;
  background-position: 0 -187px;
}
/**
 * @media
 */
@media all and (max-width: 960px) {
  ._2Sp_2u {
    height: 200px;
  }
    ._-2FDqZ h4 {
      font-size: 16px;
    }
    .sRRu5c > ul {
      display: none;
    }
  ._3OPA4m {
    display: inherit;
  }
}
